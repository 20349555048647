import styled from 'styled-components';

import { BREAKPOINTS } from '@/theme/breakpoints';

const CONTAINER_WIDTH_WIDE = 1350;
const CONTAINER_WIDTH_NORMAL = 1250;
const CONTAINER_WIDTH_NARROW = 900;

export const Container = styled.div<{
  size?: 'narrow' | 'wide';
  padding?: string;
  flex?: boolean;
  align?: string;
  direction?: string;
  height?: string;
}>`
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;
  position: relative;
  max-width: ${CONTAINER_WIDTH_NORMAL}px;
  ${(p) => p.flex === true && `display:flex;`}
  ${(p) => p.align === 'center' && `align-items: center;`}
  ${(p) => p.align === 'center-desktop' && `align-items: center;`}
  ${(p) => p.align === 'top' && `align-items: flex-start;`}
  ${(p) => p.direction && `flex-direction: ${p.direction};`}
  ${(p) => p.height && `height: ${p.height};`}
  ${(p) => p.size === 'wide' && `max-width: ${CONTAINER_WIDTH_WIDE}px;`}
  ${(p) => p.size === 'narrow' && `max-width: ${CONTAINER_WIDTH_NARROW}px;`}
  ${(p) => p.padding === 'none' && `padding: 0;`}
  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    ${(p) => p.align === 'center-desktop' && `align-items: flex-start;`}
    ${(p) => p.padding === 'no-mobile' && `padding: 0;`}
  }
`;
