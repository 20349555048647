import { createGlobalStyle } from 'styled-components';

import { BREAKPOINTS } from '@/theme/breakpoints';

export const GlobalStyles = createGlobalStyle`
  :root {
    --white: #FFFFFF;
    --grey: #3c3c3b;
    --pink: #fcd2d4;
    --beige: #F2F0EB;
    --primary-color: #0D0E13;
    --secondary-color: #FCC41E;
  }

  @font-face {
    font-family: 'Portrait';
    src: url('/fonts/Portrait-Medium-Web.eot');
    src: local('Portrait'), local('Portrait-Medium-Web'),
        url('/fonts/Portrait-Medium-Web.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Portrait-Medium-Web.woff2') format('woff2'),
        url('/fonts/Portrait-Medium-Web.woff') format('woff'),
        url('/fonts/Portrait-Medium-Web.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Portrait';
    src: url('/fonts/Portrait-MediumItalic-Web.eot');
    src: local('Portrait'), local('Portrait-MediumItalic-Web'),
        url('/fonts/Portrait-MediumItalic-Web.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Portrait-MediumItalic-Web.woff2') format('woff2'),
        url('/fonts/Portrait-MediumItalic-Web.woff') format('woff'),
        url('/fonts/Portrait-MediumItalic-Web.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Portrait';
    src: url('/fonts/Portrait-Bold-Web.eot');
    src: local('Portrait'), local('Portrait-Bold-Web'),
        url('/fonts/Portrait-Bold-Web.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Portrait-Bold-Web.woff2') format('woff2'),
        url('/fonts/Portrait-Bold-Web.woff') format('woff'),
        url('/fonts/Portrait-Bold-Web.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-display: swap;
    font-family: 'Helvetica-Neue';
    src: url('/fonts/Helvetica-Neue-Regular.eot');
    src: url('/fonts/Helvetica-Neue-Regular.woff') format('woff'),
        url('/fonts/Helvetica-Neue-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-display: swap;
    font-family: 'Helvetica-Neue';
    src: url('/fonts/Helvetica-Neue-Medium.eot');
    src: url('/fonts/Helvetica-Neue-Medium.woff') format('woff'),
        url('/fonts/Helvetica-Neue-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-display: swap;
    font-family: 'Helvetica-Neue';
    src: url('/fonts/Helvetica-Neue-Bold.eot');
    src: url('/fonts/Helvetica-Neue-Bold.woff') format('woff'),
        url('/fonts/Helvetica-Neue-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }

   * {
    margin: 0;
    //cursor: none !important;
  }

  /* .show-cursor {
    cursor: auto !important;
  }  */

  html, body {
    height: 100%;
    scroll-behavior: smooth;
    background-color: var(--white);
  }

  body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    font-family: 'HK', sans-serif;
    color: var(--grey);
    accent-color: var(--primary-color);
  }

  h1, h3 {
    font-weight: 400;
    font-family: 'Portrait';
  }

  h2, h4, h5 {
    font-weight: 600;
    font-family: 'Helvetica-Neue';
  }

  .display {
    font-size: 120px;
    line-height: 144px;
    font-weight: 600;
  }

  h1 {
    font-size: 80px;
    line-height: 96px;
  }

  h2 {
    font-size: 80px;
    line-height: 96px;
  }

  h3 {
    font-size: 60px;
    line-height: 72px;
  }

  h4 {
    font-size: 50px;
    line-height: 61px;
  }

  p.pre-title {
    font-weight: 600;
    font-family: 'Helvetica-Neue';
    font-size: 20px;
    line-height: 37px;
  }

  img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
    height: auto;
  }

  input, button, textarea, select {
    font: inherit;
  }
  
  #root, #__next {
    isolation: isolate;
    height: 100%;
  }

  html.has-scroll-smooth {
    overflow: hidden;
  }

  html.has-scroll-dragging {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .has-scroll-smooth body {
    overflow: hidden; }

  .has-scroll-smooth [data-scroll-container] {
    min-height: 100vh; }

  [data-scroll-direction="horizontal"] [data-scroll-container] {
    height: 100vh;
    display: inline-block;
    white-space: nowrap;
  }

  [data-scroll-direction="horizontal"] [data-scroll-section] {
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    height: 100%;
  }

  .c-scrollbar {
    position: absolute;
    right: 0;
    top: 0;
    width: 11px;
    height: 100%;
    transform-origin: center right;
    transition: transform 0.3s, opacity 0.3s;
    opacity: 0;

    &:hover {
      transform: scaleX(1.45);
    }
  }

  .c-scrollbar:hover, .has-scroll-scrolling .c-scrollbar, .has-scroll-dragging .c-scrollbar {
    opacity: 1;
  }

  [data-scroll-direction="horizontal"] .c-scrollbar {
    width: 100%;
    height: 10px;
    top: auto;
    bottom: 0;
    transform: scaleY(1);
  }

  [data-scroll-direction="horizontal"] .c-scrollbar:hover {
    transform: scaleY(1.3);
  }

  .c-scrollbar_thumb {
    position: absolute;
    top: 0;
    right: 0;
    background-color: black;
    opacity: 0.5;
    width: 7px;
    border-radius: 10px;
    margin: 2px;
    cursor: -webkit-grab;
    cursor: grab;
  }
    
  .has-scroll-dragging .c-scrollbar_thumb {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }

  [data-scroll-direction="horizontal"] .c-scrollbar_thumb {
    right: auto;
    bottom: 0;
  }

  .fadeInUp {
    transition: all 0.6s ease-in;
    opacity: 0;

    &.loaded {
      opacity: 1;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active{
      background-color: white;
  }

  .ring {
    position: fixed;
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    background-color: rgba(31, 30, 30, 0.4);
    border-radius: 100%;
    transform: translate(-50%, -50%);
    -webkit-transition-duration: 100ms;
    transition-duration: 100ms;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    will-change: width, height, transform, border;
    z-index: 999;
    pointer-events: none;
  }

  .dot {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    background-color: black;
    border-radius: 100%;
    transform: translate(-50%, -50%);
    z-index: 999;
    pointer-events: none;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    .display {
      font-size: 70px;
      line-height: 86px;
    }

    h1 {
      font-size: 32px;
      font-weight: 500;
      line-height: 42px;
      margin-bottom: 28px;
    }

    h2 {
      font-size: 26px;
      font-weight: 500;
      line-height: 34px;
      margin-bottom: 20px;
    }

    h3 {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 20px;
    }

    h4 {
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 20px;
    }

    p.pre-title {
      font-size:18px;
      line-height: 28px;
    }
  }
`;
