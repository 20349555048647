import { hasCookie, setCookie } from 'cookies-next';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Container } from './Container';

export const CookieConsent = () => {
  const [showConsent, setShowConsent] = useState(true);
  const cookieAge = 15552000; // age in seconds

  useEffect(() => {
    setShowConsent(hasCookie('taCookieConsent'));
  }, []);

  const acceptCookie = () => {
    setShowConsent(true);
    setCookie('taCookieConsent', 'true', { maxAge: cookieAge });
  };

  const declineCookie = () => {
    setShowConsent(true);
    setCookie('taCookieConsent', 'false', { maxAge: cookieAge });
  };

  if (showConsent) {
    return null;
  }

  const StyledWrapper = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 999;
    text-align: left;
    background-color: #fcc41e;
    color: #3c3c3b;
    padding: 30px 0;

    @media screen and (max-width: 960px) {
    }
  `;

  const StyledRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    @media screen and (max-width: 960px) {
      flex-direction: column;
      gap: 20px;
    }
  `;

  const StyledText = styled.div`
    a {
      text-decoration: none;
      font-weight: bold;
      font-style: italic;
      color: #3c3c3b;
    }

    h2 {
      font-size: 38px;
      line-height: 38px;
      margin: 0;
    }
    @media screen and (max-width: 960px) {
      padding-right: 0;
    }
  `;

  const StyledTextLink = styled(Link)`
    text-decoration: none;
    font-weight: bold;
    color: #3c3c3b;
  `;

  const StyledButtons = styled.div`
    display: flex;
    gap: 20px;

    @media screen and (max-width: 960px) {
      width: 100%;
    }
  `;

  const StyledButton = styled.div`
    button {
      background-color: transparent;
      border: 2px solid #3c3c3b;
      color: #3c3c3b;
      font-family: var(--font-poppins);
      font-weight: bold;
      padding: 4px 20px;
      font-size: 17px;
      cursor: pointer;

      @media screen and (max-width: 960px) {
        width: 100%;
      }
    }
  `;

  return (
    <StyledWrapper>
      <Container>
        <StyledRow>
          <StyledText>
            <h2>Using cookies</h2>
          </StyledText>
          <StyledText>
            We make sure you get the best experience of our website.
            {' '}
            <StyledTextLink href="/privacy-policy" target="_blank">
              Find out more.
            </StyledTextLink>
          </StyledText>
          <StyledButtons>
            <StyledButton>
              <button type="button" onClick={() => acceptCookie()}>
                That's OK
              </button>
            </StyledButton>
          </StyledButtons>
        </StyledRow>
      </Container>
    </StyledWrapper>
  );
};
