/* eslint-disable import/no-unresolved */
import { getCookie } from 'cookies-next';
import Router from 'next/router';
import { useEffect, useState } from 'react';

import { GlobalStyles } from '@/components';
import { CookieConsent } from '@/components/Shared/CookieConsent';
import { trackPageView } from '@/lib/gtm/gtm';

const App = ({ Component, pageProps }) => {
  const getLayout = Component.getLayout || ((page) => page);
  const [loadGAScripts, setLoadGAScripts] = useState(getCookie('taCookieConsent') === 'true');

  useEffect(() => {
    const interval = setInterval(() => {
      const currentConsent = getCookie('taCookieConsent');
      if (currentConsent === 'true' && !loadGAScripts) {
        setLoadGAScripts(true);
      } else if (currentConsent !== 'true' && loadGAScripts) {
        setLoadGAScripts(false);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [loadGAScripts]);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (getCookie('taCookieConsent') === 'true') {
        trackPageView(url);
      }
    };

    Router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  return getLayout(
    <div id="main-page-wrap">
      <Component {...pageProps} />
      <GlobalStyles />
      <CookieConsent />
    </div>
  );
};

export default App;
